
<template>
    <div class="row  no-gutters">
        <div class="col-lg-8">
            <div class="item-block hero-quick-promo-wrap">
            <div class="ribbon ribbon-top-card-right">
                <span class="top" v-if="book.status == 1">Top</span>
                <span class="new" v-else-if="book.status == 3">New</span>
                <span class="limited" v-else-if="book.status == 4">Limited</span>
                <span class="recommend" v-else-if="book.status == 5">Recommended</span>
                <span class="out" v-else-if="book.status == -1">Out of Stock </span>
                <span v-else-if="book.book_lists[indexBook].regular_price && book.status == 2">{{ book.book_lists[indexBook].offer }}% off</span>
            </div>
                <div class="row no-gutters w-100 pb-5">
                    <div class="col-lg-4">
                        <div class="pl-3 py-3" >
                            
                            <!-- directive -->
                            <div class="images" v-viewer="vvuezoomeroptions">
                              <img :src="bookImages" class="imagePointerShow" :options="vvuezoomeroptions">
                            </div>
                            <!-- component -->
                            <viewer :images="urlimages" v-viewer.static="{inline: true}" class="subImagesViwerDiv" :options="vvuezoomeroptions">
                              <img v-for="src in urlimages" :src="src" :key="src" class="subImagesStyle">
                            </viewer>

                        </div>
                    </div>
                    <div class="col-lg-8">
                        <div class="pr-3 py-3">
                            <h1 itemprop="name">{{ book.title }} <br> <small style="font-size: 62%;"> By <a :href="`/authors/${book.author.slug}`" class="btn btn-link text-primary px-1">{{ book.author.tamil }} ({{ book.author.name }})</a></small></h1>
                            <div class="rating-wrap">
                                <a href="#rating-distribution">
                                    <div class="stars">
                                        <span class="fa fa-star text-warning"></span>
                                        <span class="fa fa-star text-warning"></span>
                                        <span class="fa fa-star text-warning"></span>
                                        <span class="fa fa-star text-warning"></span>
                                        <span class="fa fa-star"></span>
                                    </div>
                                </a>
                                <span class="rating-count">
                                    1 ratings
                                </span>
                            </div>

                            <div class="addition-item pt-3">
                                <h4>Also available in</h4>
                                <div class="row no-gutters">
                                    <div class="col-6 col-sm-3 col-md-3" v-for="(item, index) in book.book_lists" :key="index">
                                        <div class="card text-center mr-2 my-2" :class="`${ index == indexBook ? 'bg-orange' : ''}`" @click="setaAvailability(item, index)" style="cursor:pointer">
                                            <div class="card-body" v-if="item.type == 1 && item.visible">
                                                <i class="fa fa-suitcase"></i>
                                                <h5 class="pt-2">Hard Cover</h5>
                                                <span class="price">Rs {{ (item.offer ? parseFloat(item.regular_price * inr_price) : parseFloat(item.price * inr_price)).toFixed(2) }}</span>
                                            </div>
                                            <div class="card-body" v-else-if="item.type == 2 && item.visible">
                                                <i class="fa fa-file"></i>
                                                 <h5 class="pt-2">Paperback</h5>
                                                <span class="price">Rs {{ (item.offer ? parseFloat(item.regular_price * inr_price) : parseFloat(item.price * inr_price)).toFixed(2) }}</span>
                                            </div>
                                            <div class="card-body" v-else-if="item.type == 3 && item.visible">
                                                <i class="fa fa-bullhorn"></i>
                                                 <h5 class="pt-2">Audio Book</h5>
                                                <span class="price">Rs {{ (item.offer ? parseFloat(item.regular_price * inr_price) : parseFloat(item.price * inr_price)).toFixed(2) }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="!readMore">
                                <div class="item-excerpt trunc py-2"  v-if="!readMore" data-height="250" style="overflow-wrap: break-word;" v-html="book.description.slice(0, 600)"></div>
                                <a href="javascript:;" @click="readMore = !readMore" v-show="readMoreShow" class="btn-link"> Read more...</a>
                            </div>
                            <div v-else>
                                <div class="item-excerpt trunc pt-2" v-if="readMore" data-height="250" style="overflow-wrap: break-word;" v-html="book.description"></div>
                                <a href="javascript:;" @click="readMore = !readMore" v-show="readMoreShow" class="btn-link"> Read less</a>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        <div class="col-lg-4">
            <div class="item-block">
                <div class="checkout-tools">
                    <div class="price-info-wrap">
                        <div class="item-info-wrap">
                            <div class="price item-price-wrap" v-if="book.book_lists[indexBook].offer">
                                <span class="sale-price">LKR {{ (book.book_lists[indexBook].regular_price * inr_price).toFixed(2) }}</span>
                                <span class="save-price">LKR {{ (book.book_lists[indexBook].price * inr_price).toFixed(2) }}</span>
                            </div>
                            <div class="price item-price-wrap" v-else>
                                <span class="sale-price">LKR {{ (book.book_lists[indexBook].price * inr_price).toFixed(2) }}</span>
                            </div>
                           <!--  <div class="price item-price-wrap" v-if="book.book_lists[indexBook].offer">
                                <span class="sale-price" style="font-size: 2rem;color: #28a745;">INR {{ (book.book_lists[indexBook].regular_price * 1).toFixed(2) }}</span>
                                <span class="save-price" style="font-size: 1.5rem;">INR {{ (book.book_lists[indexBook].price * 1).toFixed(2) }}</span>
                            </div>
                            <div class="price item-price-wrap" v-else>
                                <span class="sale-price" style="font-size: 2rem;color: #28a745;">INR {{ (book.book_lists[indexBook].price * 1).toFixed(2) }}</span>
                            </div> -->
                            <div class="count-input-block pt-3">
                                <input type="number" class="form-control text-center" min="1" :max="`${book.status != -1 ? book.book_lists[indexBook].qty : 1000 }`" v-model="formData.qty">
                                <span class="widget-label">Qty</span>
                            </div>
                            <div class="free-delivery-text" v-if="book.status != -1">
                                <i class="fa fa-book"></i>
                                <p>Maximum Order Quantity {{ book.book_lists[indexBook].max_order_qty }}</p>
                            </div>
                            <!-- <div class="free-delivery-text" v-if="book.status != -1">
                                <i class="fa fa-book"></i>
                                <p>Stock Quantity {{ book.book_lists[indexBook].qty }}</p>
                            </div> -->
                            <div class="free-delivery-text">
                                <i class="fa fa-truck"></i>
                                <p>Island Wide Delivery</p>
                            </div>
                            <div class="free-delivery-text">
                                <i class="fa fa-money"></i>
                                <p>Cash on Delivery Available</p>
                            </div>
                            <div class="availability-text mt-2">
                                <p>
                                   <b class="text-danger" v-if="book.status === -1"><i class="fa fa-times"></i> Out of Stock.</b> <b class="text-success" v-else><i class="fa fa-check"></i> Available.</b>
                                    <a href="/faq?a=18" target="_blank">When will my order arrive?</a><!-- help?a=deliver -->
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="btn-wrap">
                        <a href="javascript:;" @click="addtoCart" v-if="book.status != -1" class="btn add-to-cart">Add to cart</a>
                        <a href="javascript:;" @click="bookRequested" v-else class="btn btn-success add-to-basket">Request book</a>
                        <a href="javascript:;" @click="addtoWithList" class="btn btn-light add-to-wishlist border">Add to wishlist</a>
                        <div class="copy-content" data-target=".social-wrap">
                            <ul class="social-wrap">
                                <li class="label">Share</li>
                                <li><a :href="`mailto:youremail@example.com?&subject=&body=https://venpaa.lk/book/${book.slug}`" style="padding: 1rem 1rem;" target="_blank"><i class="fa fa-envelope"></i></a></li>
                                <li><a :href="`https://www.facebook.com/sharer/sharer.php?u=https://venpaa.lk/book/${book.slug}`" style="padding: 1rem 1.3rem;" target="_blank"><i class="fa fa-facebook"></i></a></li>
                                <li><a :href="`https://twitter.com/intent/tweet?url=https://venpaa.lk/book/${book.slug}`" target="_blank"><i class="fa fa-twitter"></i></a></li>
                                <li><a :href="`https://www.instagram.com/?url=https://venpaa.lk/book/${book.slug}`" style="padding: 0.9em 1.1rem 1.2rem;" target="_blank"><i class="fa fa-instagram"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12">

            <div class="biblio-wrap">
                <div class="biblio-info-wrap">
                    <h2 class="biblio-title">
                        Product details</h2>
                    <ul class="biblio-info">
    
                        <li v-if="book.book_lists[indexBook].type">
                            <label>Format</label>
                            <span v-if="book.book_lists[indexBook].type == 1">Hard Coverk</span>
                            <span v-else-if="book.book_lists[indexBook].type == 2">Paperback</span>
                            <span v-else-if="book.book_lists[indexBook].type == 3">Audio Book</span>
                            <span v-else>Not Specified</span>
                        </li>
                        <li v-if="book.book_lists[indexBook].dimension_w && book.book_lists[indexBook].dimension_h && book.book_lists[indexBook].dimension_d">
                            <label>Dimensions</label>
                            <span>
                                {{ book.book_lists[indexBook].dimension_w }}cm
                                x {{ book.book_lists[indexBook].dimension_h }}cm
                                x {{ book.book_lists[indexBook].dimension_d }}cm
                            </span>
                        </li>
                        <li v-if="book.book_lists[indexBook].weight">
                            <label>Weight</label>
                            <span>{{ book.book_lists[indexBook].weight }}g</span>
                        </li>
                        <li v-if="book.book_lists[indexBook].pages">
                            <label>Pages</label>
                            <span>{{ book.book_lists[indexBook].pages }} pages</span>
                        </li>
                        <li>
                            <label>Publisher</label>
                            <span><a :href="`/publishers/${book.publisher.slug}`" class="btn btn-link px-1">{{ book.publisher.name }}</a></span>
                        </li>
                        <li>
                            <label>Main Category</label>
                             <span><a :href="`/categories/${book.category.slug}`" class="btn btn-link px-1">{{ book.category.name }}</a></span>
                        </li>
                        <li>
                            <label>Subcategory</label>
                            <span><a :href="`/subcategories/${book.subcategory.slug}`" class="btn btn-link px-1">{{ book.subcategory.name }}</a></span>
                            
                        </li>
                        <li v-if="book.book_lists[indexBook].isbn">
                            <label>ISBN</label>
                            <span>{{ book.book_lists[indexBook].isbn }}</span>
                        </li>
                    </ul>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import EventBus from './../../event-bus'
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
Vue.use(Viewer);

export default {
    props:['book', 'auth'],
    data () {
        return {
            inr_price: 0,
            bookImages : [`${baseURL}/${this.book.book_lists[0].img}`],
            urlimages:[],
            indexBook: 0,
            formData: {
                qty: 1,
                book_list_id: {}
            },
            readMore: false,
            readMoreShow: true,
            vvuezoomeroptions:{
                title: false,
                toolbar: false,
                tooltip: false,
                rotatable: false,
                scalable: false
            },
        }
    },
    methods: {
        setaAvailability(book, index) {
            //display type of book. [hasrd cover/paper back / etc..]
            this.bookImages = `${baseURL}/${book.img}`;
            //this.$refs.childComponent.previewImg.url = `${baseURL}/${book.img}`
            //this.$refs.childComponent.previewLargeImg.url = `${baseURL}/${book.img}`
            //this.$refs.childComponent.choosedThumb.url = `${baseURL}/${book.img}`

            this.indexBook = index;
            this.formData.book_list_id = book.id;
        },
        addtoCart() {
            axios.post(`/cart/add`, this.formData)
                .then(({data}) => {
                    this.$swal({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        type: 'success',
                        title: `${data.message}`
                    });
                    EventBus.$emit('add-cart-event')

                })
                .catch((error) => {
                    if(this.auth) {
                        if (error.response && error.response.data) {
                            if(error.response.data.errors['book_list_id']) {
                                this.$swal({
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 3000,
                                    type: 'info',
                                    title: `${error.response.data.errors['book_list_id'][0]}`
                                });
                            } else {
                                this.$swal({
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 3000,
                                    type: 'info',
                                    title: `${error.response.data.errors['qty'][0]}`
                                });
                            }

                        }
                    } else {
                        window.location.href = '/login'
                    }

                })
        },
        addtoWithList() {
            axios.post(`/wishlist/add`, this.formData)
                .then(({data}) => {
                    this.$swal({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        type: 'success',
                        title: `${data.message}`
                    });

                })
                .catch((error) => {
                    if(this.auth) {
                        if (error.response && error.response.data) {
                            if(error.response.data.errors['book_list_id']) {
                                this.$swal({
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 10000,
                                    type: 'error',
                                    title: `${error.response.data.errors['book_list_id'][0]}`
                                });
                            } else {
                                this.$swal({
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 10000,
                                    type: 'error',
                                    title: `${error.response.data.errors['qty'][0]}`
                                });
                            }

                        }
                    } else {
                        window.location.href = '/login'
                    }

                })
        },
        bookRequested() {
            axios.post(`/requested/book`, this.formData)
                .then(({data}) => {
                    this.$swal({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        type: 'success',
                        title: `${data.message}`
                    });

                })
                .catch((error) => {
                    if(this.auth) {
                        if (error.response && error.response.data) {
                            if(error.response.data.errors['book_list_id']) {
                                this.$swal({
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 3000,
                                    type: 'error',
                                    title: `${error.response.data.errors['book_list_id'][0]}`
                                });
                            } else {
                                this.$swal({
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 3000,
                                    type: 'error',
                                    title: `${error.response.data.errors['qty'][0]}`
                                });
                            }

                        }
                    } else {
                        window.location.href = '/login'
                    }

                })
        },
    },
    mounted() {
        this.inr_price = (typeof process.env.MIX_INR_PRICE !== 'undefined') ?  process.env.MIX_INR_PRICE : 4.5;

        const images = this.book.images;
        for (let index = 0; images.length > index; index++) {
            
            //this urlimages is loading of subimages wich uploaded by v-viwer pluging 
            this.urlimages.push(`${baseURL}/${images[index].img}`);
            
        }


        if(this.book.description.length < 600) {
            this.readMoreShow = false;
        }

        this.formData = { qty: 1, book_list_id: this.book.book_lists[0].id }

    },

}
</script>

<style>
.scroller-at-bottom .thumb-list {
    margin-top: 2rem !important;  
}
.imagePointerShow{
    cursor:pointer
}
.subImagesViwerDiv{
    display:flex;
    width:100%;
    height:50px;
    margin-top:10px;
}
.subImagesStyle{
    width:46px;
    cursor:pointer;
}

.viewer-container img {
    transform: scale(1.5) !important;
}
</style>