/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require("./bootstrap");

//require('../../public/frontend_assets/js/plugins')

//require('../../public/frontend_assets/js/main')
require("slick-carousel");

//require('jquery.mmenu');

import Isotope from "isotope-layout";

import LazyLoad from "vanilla-lazyload";

import { MainSlider, BookSlider } from "./assets/front/Venpaa";

$(document).ready(function($) {
    if (!sessionStorage["importantMessageClosed"]) {
        $("#importantMessage").removeAttr('hidden');
    }

    var lazyLoadInstance = new LazyLoad({
        elements_selector: ".lazy"
        // ... more custom settings?
    });
    lazyLoadInstance.update();

    let elem = document.querySelector(".grid");

    if (elem) {
        let iso = new Isotope(elem, {
            itemSelector: ".grid-item"
        });
    }

    /* if (typeof(Storage) !== "undefined") {
    // Store
    document.getElementById("setModalAgree").addEventListener("click", function(){
      localStorage.setItem("vpModalAgree", true); 
    });
    //localStorage.setItem("modalAgree", false); 
    //Retrieve
    if(localStorage.getItem("vpModalAgree") == 'true') {
      $('#myModal').modal('hide');
    } else {
      $('#myModal').modal('show');
    }
  } */

    $(".tbd-dropdown").hover(
        function() {
            $(this)
                .addClass("open")
                .delay(200);
        },
        function() {
            $(this)
                .removeClass("open")
                .delay(200);
        }
    );

    //cart page
    $("#cartEdit input").change(function() {
        $(this)
            .closest("form")
            .submit();
    });

    //sort feature, price
    $("#sortFeature input, #sortPrice input").change(function() {
        $(this)
            .closest("form")
            .submit();
    });

    //sort feature, price
    $("#sortPrice input").change(function() {
        $(this)
            .closest("form")
            .submit();
    });

    $("#sortBookForm select").change(function() {
        $(this)
            .closest("form")
            .submit();
    });

    //main search
    $("#book-search-form").keydown(function(e) {
        var key = e.which;
        if (key == 13) {
            // As ASCII code for ENTER key is "13"
            $("#book-search-form").submit(); // Submit form code
            return false;
        }
    });

    //book slider show
    if (document.querySelector(".slider")) {
        new BookSlider(".slider");
    }
    //main slider banner
    if (document.querySelector(".main-slider")) {
        new MainSlider(".main-slider");
    }

    $("#btnImportantMessageHide").click( function () {
        $("#importantMessage").hide();
        sessionStorage["importantMessageClosed"] = true;
    })
});

window.Vue = require("vue");

import VueSweetalert2 from "vue-sweetalert2";
// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2);

import {
    ValidationProvider,
    ValidationObserver,
    extend,
    configure
} from "vee-validate";
import * as rules from "vee-validate/dist/rules";

configure({
    useConstraintAttrs: false,
    classes: {
        invalid: "is-invalid"
    }
});

// loop over all rules
for (let rule in rules) {
    // add the rule
    extend(rule, rules[rule]);
}

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));
//Vue.component("ValidationProvider", ValidationProvider);
//Vue.component("ValidationObserver", ValidationObserver);

import VueCarousel from "@chenfengyuan/vue-carousel";

Vue.component(VueCarousel.name, VueCarousel);

Vue.component(
    "vb-newslatter",
    require("./front/components/Newsletters.vue").default
);
Vue.component(
    "vb-order-cancelled",
    require("./front/components/OrderCancelled.vue").default
);
Vue.component(
    "vb-single-book",
    require("./front/components/SingleBook.vue").default
);
Vue.component(
    "vb-crop-avatar",
    require("./front/components/CropAvatar.vue").default
);
Vue.component("vb-cart", require("./front/components/Cart.vue").default);
Vue.component(
    "vb-checkout-order",
    require("./front/components/CheckoutOrder.vue").default
);
Vue.component("vb-search", require("./front/components/Search.vue").default);
Vue.component(
    "vb-overseas-deliver",
    require("./front/components/OverseasDeliver.vue").default
);
Vue.component(
    "vb-overseas-notsamecountry",
    require("./front/components/OverseasCountryChange.vue").default
);
Vue.component(
    "vb-overseas-payment",
    require("./front/components/CheckoutMakePayment.vue").default
);
Vue.component(
    "vb-add-to-cart",
    require("./front/components/AddToCartBtn.vue").default
);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: "#app",
    data() {
        return {
            //subtotal status
            orderSubTotal: '0'
        };
    }
});
