<template>
<div class="form-row col-12 padding-remove">
<label for="ship_country">Country</label>
	<input type="text" readonly name="ship_country" id="ship_country" v-model="this.countryChoose"  class="form-control">
</div>
</template>

<script>
import EventBus from './../../event-bus'

export default {
    data(){
        return {
            countryChoose: "Sri Lanka",
        }
    },
    methods: {      
    },
    mounted() {
       EventBus.$on("clicked-event-get-delivery-country-not_same_country", mySelectedCountry => {
             this.countryChoose = mySelectedCountry;
        });  
    }
    
}
</script>
