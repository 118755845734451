<template>
    <a href="javascript:;" @click="orderCancelled" class="btn btn-secondary"><i class="fa fa-times"></i></a>
</template>

<script>

export default {
    props:['url'],
    data () {
        return {
            openCartDropdown:false,
        }
    },
    methods: {
        orderCancelled() {
            this.$swal({
                title: 'Are you sure?',
                text: 'You can\'t revert your order',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes Cancel it!',
                cancelButtonText: 'No, Keep it!',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result) => {
                if(result.value) {

                    axios.delete(this.url, { _method: 'delete' })
                        .then((response) => {
                            this.$swal({
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000,
                                type: 'success',
                                title: `${response.data.message}`,
                            });

                            setTimeout(function () {
                                location.reload()
                            }, 1000);

                        })
                        .catch((error) => {
                            this.$swal({
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000,
                                type: 'error',
                                title: `${error.response.data.message}`
                            });
                        })
                }
            });
        },
    },

}
</script>
