<template>
    <div class="form-row">
        <div class="mb-3" style="width: 100%">
            <br />
            <h2 style="font-weight: 100"><b>Payment Methods</b></h2>
            <hr />
        </div>
        <div class="form-group col-12">
            <div class="paymentMethodStyles" v-show="!cash_pay_only">
                <table>
                    <tr>
                        <td>
                            <input
                                type="radio"
                                name="payment_methods"
                                id="card"
                                @change="setDeliveryMethodCharge"
                                v-model="payment_methods"
                                value="card"
                                class="radio-input"
                            />
                        </td>
                        <td>
                            <img
                                src="frontend_assets/img/delivery/card_payment_method.png"
                            />
                        </td>
                        <td>
                            <label for="card" class="radio-label mb-0"
                                ><b>Card Payment</b></label
                            >
                            <br /><span class="ml-0"
                                >Online Payment.
                                <a href="javascript:;" @click="cardMore"
                                    >Click more details</a
                                ></span
                            >
                        </td>
                    </tr>
                </table>
            </div>
            <div v-show="mySelectedDeliverCountry == 0">
                <div class="paymentMethodStyles" v-show="cash_pay_only">
                    <table>
                        <tr>
                            <td>
                                <input
                                    type="radio"
                                    name="payment_methods"
                                    id="cashdelivery"
                                    @change="setDeliveryMethodCharge"
                                    v-model="payment_methods"
                                    value="cash"
                                    class="radio-input"
                                />
                            </td>
                            <td>
                                <img
                                    v-if="delivery_methods === 'cash_post'"
                                    src="frontend_assets/img/delivery/cash_on_delivery.png"
                                    style="width: 55px"
                                />
                                <img
                                    v-if="delivery_methods === 'cash_home'"
                                    src="frontend_assets/img/delivery/home_delivery.png"
                                    style="width: 55px"
                                />
                            </td>
                            <td>
                                <div v-if="delivery_methods === 'cash_post'">
                                    <label
                                        for="cashdelivery"
                                        class="radio-label mb-0"
                                    >
                                        <b>Cash on delivery - Post</b></label
                                    >
                                    <br />
                                    <span class="ml-0"
                                        >Pay and collect at your local post
                                        office.
                                        <a
                                            href="javascript:;"
                                            @click="cashMorePost"
                                            >Click more details</a
                                        ></span
                                    >
                                </div>
                                <div v-if="delivery_methods === 'cash_home'">
                                    <label
                                        for="cashdelivery"
                                        class="radio-label mb-0"
                                    >
                                        <b>Cash on delivery - Home</b></label
                                    >
                                    <br />
                                    <span class="ml-0"
                                        >Pay and collect at your house.
                                        <a
                                            href="javascript:;"
                                            @click="cashMoreHome"
                                            >Click more details</a
                                        ></span
                                    >
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="paymentMethodStyles" v-show="!cash_pay_only">
                    <table>
                        <tr>
                            <td>
                                <input
                                    type="radio"
                                    name="payment_methods"
                                    id="bankdelivery"
                                    @change="setDeliveryMethodCharge"
                                    v-model="payment_methods"
                                    value="bank"
                                    class="radio-input"
                                />
                            </td>
                            <td>
                                <img
                                    src="frontend_assets/img/delivery/bank_deposit.png"
                                    style="width: 55px"
                                />
                            </td>
                            <td>
                                <label
                                    for="bankdelivery"
                                    class="radio-label mb-0"
                                    ><b>Bank Deposit</b></label
                                >
                                <br /><span class="ml-0"
                                    >Direct deposit to bank and get it delivered
                                    to home.
                                    <a
                                        href="javascript:;"
                                        @click="bankDepositMore"
                                        >Click more details</a
                                    ></span
                                >
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <div
            class="form-group col-12"
            v-show="payment_methods == 'bank' && mySelectedDeliverCountry == 0"
        >
            <label>Bank Slip</label>
            <input type="file" name="slip" class="form-control file mb-1" />
            <label>Bank Slip No</label>
            <input type="text" name="slip_no" class="form-control" />
        </div>
    </div>
</template>

<script>
import EventBus from "./../../event-bus";
export default {
    props: ["carts", "countryselect"],
    name: "CheckoutOrder",
    data() {
        return {
            mySelectedDeliverCountry: this.countryselect == 0 ? 0 : 1, //0 for sri lanka. 1 is for other country
            subtotal: 0,
            weightTotal: 0,
            delivery: {
                lo: 450.0,
                express: 500.0,
                lvp: 465.0,
                pronto: 500.0,
            },
            // inr_price:process.env.MIX_INR_PRICE,
            delivery_amount: 0,
            payment_methods: "card",
            delivery_methods: "normal",
            cash_pay_only: false,
        };
    },
    methods: {
        setDeliveryMethodCharge() {
            // if(this.payment_methods == 'cash' && this.delivery_methods == 'normal') { //cash
            //     this.delivery_amount = (this.subtotal < 3000 ? this.delivery.lvp : 0);
            // } else if(this.payment_methods == 'cash' && this.delivery_methods == 'express') {
            //     this.delivery_amount = this.expressDeliveyCal();//(this.subtotal < 3000 ? this.expressDeliveyCal() : 0);
            // } else if(this.payment_methods == 'cash' && this.delivery_methods == 'pickup') {
            //     this.delivery_amount = (this.subtotal < 3000 ? this.delivery.pickup : 0);
            // } else if(this.payment_methods == 'bank' && this.delivery_methods == 'normal') { //bank
            //     this.delivery_amount = (this.subtotal < 3000 ? this.delivery.lo : 0);
            // } else if(this.payment_methods == 'bank' && this.delivery_methods == 'express') {
            //     this.delivery_amount = this.expressDeliveyCal();
            // } else if(this.payment_methods == 'bank' && this.delivery_methods == 'pickup') {
            //     this.delivery_amount = (this.subtotal < 3000 ? this.delivery.pickup : 0);
            // } else if(this.payment_methods == 'card' && this.delivery_methods == 'normal') { //card
            //     this.delivery_amount = (this.subtotal < 3000 ? this.delivery.lo : 0);
            // } else if(this.payment_methods == 'card' && this.delivery_methods == 'express') {
            //     this.delivery_amount = this.expressDeliveyCal();
            // } else if(this.payment_methods == 'card' && this.delivery_methods == 'pickup') {
            //     this.delivery_amount = (this.subtotal < 3000 ? this.delivery.pickup : 0);
            // }
            if (
                this.payment_methods == "cash" &&
                this.delivery_methods == "cash_post"
            ) {
                //cash
                this.delivery_amount =
                    this.subtotal < 3000 ? this.delivery.lvp : 0;
            } else if (
                this.payment_methods == "cash" &&
                this.delivery_methods == "cash_home"
            ) {
                this.delivery_amount =
                    this.subtotal < 3000 ? this.delivery.pronto : 0;
            } else if (
                this.payment_methods == "bank" &&
                this.delivery_methods == "normal"
            ) {
                //bank
                this.delivery_amount =
                    this.subtotal < 3000 ? this.delivery.lo : 0;
            } else if (
                this.payment_methods == "bank" &&
                this.delivery_methods == "express"
            ) {
                this.delivery_amount =
                    this.subtotal < 3000 ? this.delivery.express : 0;
            } else if (
                this.payment_methods == "bank" &&
                this.delivery_methods == "pickup"
            ) {
                this.delivery_amount =
                    this.subtotal < 3000 ? this.delivery.pickup : 0;
            } else if (
                this.payment_methods == "card" &&
                this.delivery_methods == "normal"
            ) {
                //card
                this.delivery_amount =
                    this.subtotal < 3000 ? this.delivery.lo : 0;
            } else if (
                this.payment_methods == "card" &&
                this.delivery_methods == "express"
            ) {
                this.delivery_amount =
                    this.subtotal < 3000 ? this.delivery.express : 0;
            } else if (
                this.payment_methods == "card" &&
                this.delivery_methods == "pickup"
            ) {
                this.delivery_amount =
                    this.subtotal < 3000 ? this.delivery.pickup : 0;
            }
        },
        normalMore() {
            this.$swal({
                title: "<h1>Normal delivery</h1>",
                html: `<p>The books will be delivered directly to your home within 3 - 5 working days. Pay with cash or card.</p>`,
            });
        },
        cashMorePost() {
            this.$swal({
                title: "<h1>Cash on delivery</h1>",
                html: `<p>You will receive a letter from your local post office for collection. Pay and collect it from them within 5 working days.</p>`,
            });
        },
        cashMoreHome() {
            this.$swal({
                title: "<h1>Cash on delivery</h1>",
                html: `<p>You will receive the items to your home within 5 working days. Pay and collect from the courier</p>`,
            });
        },
        bankDepositMore() {
            this.$swal({
                title: "<h1>Bank Account Details</h1>",
                html: `<p class="text-left ml-5">Account: <strong>Venpaa Pvt Ltd</strong></p>
                <p class="text-left ml-5">Bank: <strong>Sampath Bank</strong></p>
                <p class="text-left ml-5">Branch: <strong>Kotahena</strong></p>
                <p class="text-left ml-5">Account Type: <strong>Savings Account</strong></p>
                <p class="text-left ml-5">Account No: <strong>103714011266</strong></p>
                <p>Direct deposit the amount to the below savings account and the books will be delivered directly to your home within 3-5 working days.<br> <a href="/help?a=bank-deposit">More details here</a></p>`,
            });
        },
        cardMore() {
            this.$swal({
                title: "<h1>Online Payment Details</h1>",
                html: `<p>We accepts all major credit cards, mobile wallets and internet banking payment option systems</p>`,
            });
        },
        expressMore() {
            this.$swal({
                title: "<h1>Express delivery</h1>",
                html: `<p>The books will be delivered directly to your home within 2 working days. Pay with cash or card on delivery.</p>`,
            });
        },
        pickupMore() {
            this.$swal({
                title: "<h1>Pickup in store</h1>",
                html: `<p>Pay online and collect the book at our below physical store at your convenience.</p>
                        <address>20A, Hindu College Road, 40000,<br>
                        Jaffna Town,<br>
                        Sri Lanka<br>
                        (Opposite to Jaffna Hindu College Playground’s entrance)
                        </address`,
            });
        },
        expressDeliveyCal() {
            //1kg should be a Rs.350 upto per 1kg appending Rs.100 | weightTotal out gram
            let expressDelivey = 350;

            let TotalKg = this.weightTotal / 1000;
            let loopTotalKg = Math.floor(TotalKg);
            for (let index = 0; index < loopTotalKg; index++) {
                expressDelivey += 100 * 1;
            }
            return expressDelivey; //assign delivery_amount
        },
    },
    mounted() {
        EventBus.$on(
            "clicked-event-get-delivery-country",
            (mySelectedCountry) => {
                this.mySelectedDeliverCountry = mySelectedCountry;
            }
        );

        // this.inr_price = (typeof process.env.MIX_INR_PRICE !== 'undefined') ?  process.env.MIX_INR_PRICE : 1.0;

        let subtotal = _.sumBy(this.carts, (item) =>
            parseFloat(item.price * item.qty)
        );
        // let subtotal = _.sumBy(this.carts, (item) => parseFloat((item.price * this.inr_price) * item.qty) );
        this.subtotal = subtotal;

        let weightTotal = _.sumBy(
            this.carts,
            (item) => parseInt(item.book_list.weight) * item.qty
        );
        this.weightTotal = weightTotal;

        let delivery = _.filter(
            this.delivery_charges,
            (o) =>
                o.weight_from <= this.weightTotal &&
                o.weight_to >= this.weightTotal
        );

        if (delivery.length > 0) {
            this.delivery = delivery[0];
        }
        this.setDeliveryMethodCharge();
    },

    created() {
        EventBus.$on("pay-delivery-methods", (methods) => {
            console.log(methods);
            this.delivery_methods = methods.delivery;
            this.payment_methods = methods.pay;
            this.cash_pay_only = methods.cash_pay;
        });
    },
};
</script>

<style scoped>
.cart-subtotal {
    background-color: #fff0e0;
}
.order-total {
    background-color: #f79939;
}

.form-control.file {
    height: calc(1.5em + 0.75rem + 9px);
}
.paymentMethodStyles > table > tr > td:nth-child(2) {
    width: 120px;
    text-align: center;
}
.paymentMethodStyles {
    margin: 5px 0px;
    padding: 1%;
    background-color: #dedede36;
    border: 1px solid #ffffff;
}
</style>
