<template>
    <vue-autosuggest
        ref="refName"
        :suggestions="suggestions"
        :input-props="{
            id: 'autosuggest__input',
            class: 'text-input',
            placeholder: 'Search for books by title / author',
            name: 'q',
        }"
        @input="onInputChange"
        @selected="onSelected"
        :get-suggestion-value="getSuggestionValue"
    >
        <template slot-scope="{ suggestion }">
            <span class="my-suggestion-item"
                >{{ suggestion.item.title }} by
                {{ suggestion.item.author.name }}</span
            >
        </template>
    </vue-autosuggest>
</template>

<script>
import { VueAutosuggest } from "vue-autosuggest";

export default {
    components: { VueAutosuggest },
    data() {
        return {
            selected: "",
            suggestions: [],
        };
    },
    methods: {
        onSelected(item) {
            this.selected = item.item.title;
            var url = `/search?q=${item.item.title}`;
            window.location = url;
        },
        onInputChange(text) {
            // event fired when the input changes
            axios
                .get(`/search/ajax`, { params: { q: text } })
                .then(({ data }) => {
                    if (text.length !== 0) {
                        this.suggestions = [
                            {
                                data,
                            },
                        ];
                    } else {
                        this.suggestions = [];
                    }
                })
                .catch((error) => {
                    this.$swal({
                        toast: true,
                        position: "top-end",
                        showConfirmButton: false,
                        timer: 3000,
                        type: "error",
                        title: `${error.response.data.message}`,
                    });
                });
        },
        getSuggestionValue(suggestion) {
            return suggestion.item.title;
        },
    },
};
</script>
<style>
.search-wrap input {
    width: 100%;
    padding: 0.5rem;
}

.search-wrap ul {
    width: 100%;
    color: rgba(30, 39, 46, 1);
    list-style: none;
    margin: 0;
    padding: 0.5rem 0 0.5rem 0;
}
.search-wrap li {
    margin: 0 0 0 0;
    border-radius: 5px;
    padding: 0.75rem 0 0.75rem 0.75rem;
    display: flex;
    align-items: center;
}
.search-wrap li:hover {
    cursor: pointer;
}

.autosuggest-container {
    display: flex;
    justify-content: center;
    width: 280px;
}

#autosuggest {
    width: 100%;
    display: block;
}
.autosuggest__results-item--highlighted {
    background-color: #f2663abf;
}

.autosuggest__results-container {
    position: relative;
    width: 100%;
    background-color: #fff;
}

.autosuggest__results {
    background-color: #fff;
    font-weight: 300;
    margin: 0;
    position: absolute;
    z-index: 10000001;
    width: 100%;
    border: 1px solid #e0e0e0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 0;
    overflow: scroll;
    max-height: 400px;
}
</style> 