var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vue-autosuggest',{ref:"refName",attrs:{"suggestions":_vm.suggestions,"input-props":{
        id: 'autosuggest__input',
        class: 'text-input',
        placeholder: 'Search for books by title / author',
        name: 'q',
    },"get-suggestion-value":_vm.getSuggestionValue},on:{"input":_vm.onInputChange,"selected":_vm.onSelected},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var suggestion = ref.suggestion;
return [_c('span',{staticClass:"my-suggestion-item"},[_vm._v(_vm._s(suggestion.item.title)+" by\n            "+_vm._s(suggestion.item.author.name))])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }