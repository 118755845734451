<template>
<div class="form-row col-12 padding-remove">
    <div class="card">
        <h2 class="font-style-overseas"><b> We do international shipping</b></h2>
        <hr>      
    <div class="card-content">
          <div class="card-body cleartfix">
              <div class="row">
              <div class="col-sm-12 col-md-6">
                <span>Find Your Shipping Destination</span>
              </div>
              <div class="col-sm-12 col-md-6"> 
                 <select class="form-control" @change="getAvailableServices" v-model="editedItem.countryId" :value="editedItem.countryId">
                    <option value="0">Sri Lanka (Domestic)</option>
                    <option v-for="(items,index) in countryAvailable" :key="index" :value="items.id">
                        {{items.country}}
                    </option>
                </select>
              </div>
              </div>
              <div v-if="servicesAvailable.length > 0">
                    <br><br>
                    <h2 class="text-center">Select Courier Service</h2>
                    <hr>
                    <div class="row make-padding" >
                        <div v-for="(itemCountry, index) in servicesAvailable" :key="index" class="parent_div">
                            <div v-for="(itemCourier, index1) in itemCountry.get_countries" :key="index1" class="">
                            <div @click="selectCourierService(itemCourier)" :class="`${serviceChecked.selectedServiceId == itemCourier.id ? 'selectedService' : 'chargesAvailable' }`">    
                            <h5 class="text-center" style="text-transform:capitalize"><img style="width:100px;heigth:50px" :src="(itemCourier.serviceicon == 'no') ? '/frontend_assets/img/delivery/delivery_courier.png' : '../'+itemCourier.serviceicon  "></h5>
                            <hr>
                                   <h5 class="text-center"><b>{{itemCourier.courier_service}} | Rs. {{ (multiple > 11) ? makePrice("0.00",itemCourier.pivot.courier_charge*multiple) : makePrice(itemCourier.pivot.base_charge,itemCourier.pivot.courier_charge) }}</b></h5>
                            </div>
                            </div>
                        </div>
                    </div>
              </div>
              <input type="hidden" id="selcted_courier" name="selcted_courier_final" v-model="this.serviceChecked.selectedServiceId">
              <input type="hidden" id="selcted_country" name="selcted_country_final" v-model="this.serviceChecked.selectedCountryId">
              <br>
              <div class="row make-padding no_courier_found" v-if="this.serviceCount == 0 && this.editedItem.countryId != 0">
                   <p><i class="fa fa-warning"></i> Sorry . Unable to find courier partner.</p>
              </div>
          </div>
        </div>
    </div> 
</div>
</template>

<script>
import EventBus from './../../event-bus'

export default {
    name:"OverseasDeliver",
    props:[
        "multiple","countryselected"
    ],
    data() {
        return {
            subtotal: 0,
            editedItem:{
              countryId: (this.countryselected == '') ? 0 : this.countryselected,  
            },
            serviceCount:"",
            countryAvailable : [],
            servicesAvailable:[],
            serviceChecked:{
                selectedCountryId:0,
                selectedServiceId:0,
                selectedServiceValue:0,
            },    
            weightTotal: 0,
            delivery: {
                lo:450.00,
                express:500.00,
                lvp:465.00,
            },
            inr_price:process.env.MIX_INR_PRICE,
            delivery_amount: 0,
            payment_methods: 'card',
            delivery_methods: 'normal',
        }
    },
    methods: {
        //Arshad
        getAvailableCountry(){
            axios.get(`/overseas/country/charges_getCountry`, { _method: 'get' })
                .then(({data}) => {
                    this.countryAvailable = data;
                })
                .catch((error) => {
                    this.$swal({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        type: 'error',
                        title: `${error.response.data.message}`
                    });

                })
        },
        getAvailableServices(){
              this.serviceCount = 1000; //1000 just a number that to validate, any services categories founded   
              this.serviceChecked.selectedServiceId = 0; 
              this.serviceChecked.selectedCountryId = 0; 
              this.selectCourierService(this.serviceChecked);
              this.serviceChecked.selectedCountryId = this.editedItem.countryId
              axios.get(`/overseas/country/charges/${this.editedItem.countryId}`, { _method: 'get' })
                .then(({data}) => {
                    this.servicesAvailable = data;
                    (data.length > 0) ? EventBus.$emit("clicked-event-get-delivery-country-not_same_country", data[0].country ) : EventBus.$emit("clicked-event-get-delivery-country-not_same_country", "Sri Lanka" );
                    (data.length > 0) ? this.serviceCount = 1 : this.serviceCount = 0; //this is to validate , any serivices founded or not. If not this will display the doestnot ship message.
                    let counte = 0;
                    if(data.length > 0 && counte == 0){  
                        counte = 1;console.info(data);
                        let dataGet = {
                            id:data[0].get_countries[0].id,
                            pivot:{
                                courier_charge : data[0].get_countries[0].pivot.courier_charge,
                                base_charge : data[0].get_countries[0].pivot.base_charge 
                            }
                        };
                        this.selectCourierService(dataGet);
                    }
                })
                .catch((error) => {
                    this.$swal({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        type: 'error',
                        title: `${error.response.data.message}`
                    });

                })     
              EventBus.$emit("clicked-event-get-delivery-country", this.serviceChecked.selectedCountryId)  
              EventBus.$emit("clicked-event-get-delivery-country-not_same_country", "Sri Lanka" );  
        },    
        makePrice(base_courier_charge,courier_charge){
            //1 kilo calculation made from here
            let cal = parseFloat(base_courier_charge);
            if(this.multiple == 1){
                return courier_charge.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');    
            }else if(this.multiple > 1 && this.multiple < 12){
                let charges = cal + (courier_charge * (this.multiple-1) );
                return charges.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
            }else{
                let items = courier_charge+cal;
                return items.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');  
            }    
            
        },
        selectCourierService(courierId){
                let weigthSize;
                if(this.multiple == 1){
                    weigthSize = 1;
                }else if(this.multiple > 1 && this.multiple < 12){
                     weigthSize = this.multiple - 1;     
                }else{
                    weigthSize = this.multiple;
                }    
                if(this.serviceChecked.selectedCountryId > 0){
                    this.selectedService = courierId.id;
                    this.serviceChecked.selectedServiceId = courierId.id;
                    this.serviceChecked.selectedServiceValue =  (this.multiple > 11) ? courierId.pivot.courier_charge*weigthSize : ( (this.multiple == 1 ) ? courierId.pivot.courier_charge : parseFloat(courierId.pivot.base_charge)+courierId.pivot.courier_charge*weigthSize );
                    EventBus.$emit("clicked-event", this.serviceChecked);  
                }else{
                    this.selectedService = 0;
                    this.serviceChecked.selectedServiceId = 0;
                    this.serviceChecked.selectedServiceValue =  0;
                    EventBus.$emit("clicked-event", this.serviceChecked);
                }
        },
        //Arshad
      
    },
    mounted() {
        //this.setDeliveryMethodCharge();
        this.getAvailableCountry();
        this.getAvailableServices();
    }
    
}
</script>

<style scoped>
.cart-subtotal {
    background-color: #fff0e0;
}
.order-total {
    background-color: #f79939;
}

.form-control.file {
    height: calc(1.5em + 0.75rem + 9px);
}
.card{
    width: 100%;
    padding: 2%;
    border: 2px solid #715539;
}
.padding-remove{
    padding: 0px;
}
.make-padding{
    padding: 0% 10%;
}
.chargesAvailable{
    background-color:#f1f1f1;
    padding: 10px;
    margin: 1%;
    width: 100%;
    border: 1px solid #b7b7b6;
}
.parent_div{
    display: inherit;
    width: 100%;
}
.parent_div > div {
    display: inherit;
    width: 100%;
} 
.font-style-overseas{
    color: #7f4e1d;
    font-weight: 400;
    font-family: monospace;
}
.no_courier_found{
    background-color: #f3f1f1;
    color: black;
    border: 1px solid black;
}
@media screen and (max-width: 575px){
    .parent_div{
        display: inherit;
        width: 100%;
    } 
    .make-padding{
        display: block;
        padding: 0% 10%;
    }
    /* .parent_div > div {
        display: inherit;
        width: 50%;
    }  */
    .chargesAvailable{
        width: 100%;
    }  
}
@media screen and (max-width: 990px) and (min-width: 576px){
    .parent_div{
        display: inherit;
        width: 100%;
    }
    .parent_div > div {
        display: inherit;
        width: 100%;
    } 
    .chargesAvailable{
        width: 100%;
    }  
}
@media screen and (min-width: px){
    .parent_div{
        display: inherit;
        width: 50%;
    }
    .parent_div > div {
        display: inherit;
        width: 100%;
    } 
    .chargesAvailable{
        width: 100%;
    }  
}
.selectedService{
    background-color:#fdf8e3;
    padding: 10px;
    width: 100%;
    margin: 1%;
    border: 2px solid #555539;
}
</style>