<template>
    <div class="basket-wrap">
        <span class="total">Rs. {{ total.toFixed(2) }}</span>
        <div v-if="cartItem.length > 0">
            <a rel="nofollow" href="javascript:;" class="basket-btn" :class="`${cartItem.length > 0 ? 'dropdown-toggle' : ''}`" :data-toggle="`${cartItem.length > 0 ? 'dropdown' : ''}`" role="button" aria-expanded="true">
                <span class="item-count">{{ cartItem.length }}</span>
                <i class="fa fa-shopping-bag"></i> My Cart
            </a>
            <ul class="dropdown-menu shopping-cart-items" style="min-width: 36rem;" role="menu">
                <li  v-for="(item, index) in cartItem" :key="index" class="my-2">
                    <span class="d-flex">
                        <span class="justify-content-start">
                            <img :src="`/${item.book_list.img}`" width="50px" alt="" />
                        </span>
                        <span class="justify-content-center align-items-center" style="width: 215px;">
                            <span class="item-name" :title="item.book_list.book.title"><a :href="`/book/${item.book_list.book.slug}`">{{item.book_list.book.title.substr(0, 15) }}</a></span>
                            
                            <span class="item-type" style="color:#444" v-if="item.book_list.type == 1">Hard Cover</span>
                            <span class="item-type" style="color:#444" v-else-if="item.book_list.type == 2">Paperback</span>
                            <span class="item-type" style="color:#444" v-else-if="item.book_list.type == 3">Audio Book</span>
                            <span class="item-type" style="color:#444" v-else>Not Specified</span>

                            <span class="item-price" style="color:#444">
                                <span>Rs {{ (item.price * inr_price).toFixed(2) }}</span>
                            </span>

                            <span class="item-quantity" style="color:#444">X {{ item.qty }}</span>
                        </span>
                        <span class="justify-content-end align-items-center" style="width: 20px;">
                            <button type="button" class="btn btn-danger mt-4" style="width: 36px;height: 34px;" @click="removeToCart(item)"><i class="fa fa-times"></i></button>
                        </span>
                    </span>
                </li>
                <li class="divider my-3"></li>
                <li class="checkout"><a href="/checkout" class="text-center btn-link">Checkout</a></li>
                <li class="checkout"><a href="/cart" class="text-center btn-link cart">View Cart</a></li>
            </ul>
        </div>
        <div v-else>
            <a rel="nofollow" href="javascript:;" class="basket-btn dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="true">
                <span class="item-count">{{ cartItem.length }}</span>
                <i class="fa fa-shopping-bag"></i> My Cart
            </a>
            <ul class="dropdown-menu shopping-cart-items" style="min-width: 36rem;" role="menu">
                <li class="my-2">
                   <em>No book in cart</em> 
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import EventBus from './../../event-bus'

export default {
    props:['user'],
    data () {
        return {
            total:0,
            cartItem:[],
            inr_price:0,
            openCartDropdown:false,
        }
    },
    methods: {
        totalSum() {
            let total = _.sumBy(this.cartItem, (item) => ( parseFloat((item.price * this.inr_price) * item.qty) ));
            this.total = total;
        },
        getToCart() {
            axios.get(`/cart/get`)
                .then(({data}) => {
                    this.cartItem = data;
                })
                .catch((error) => {
                    this.$swal({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        type: 'error',
                        title: `${error.response.data.message}`
                    });

                })
        },
        removeToCart(item) {
            axios.delete(`/cart/delete/${item.id}`, { _method: 'delete' })
                .then(({data}) => {
                    this.cartItem = data;
                    this.getToCart();
                    
                    this.$swal({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        type: data.status,
                        title: data.message,
                        onClose: () => {
                            //if(this.cartItem.length == 0) {
                                location.reload()
                            //}
                        }
                    });
                })
                .catch((error) => {
                    this.$swal({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        type: 'error',
                        title: `${error.response.data.message}`
                    });

                })
        },
    },
    mounted() {
        this.inr_price = (typeof process.env.MIX_INR_PRICE !== 'undefined') ?  process.env.MIX_INR_PRICE : 4.5;
        if(this.user) {
            EventBus.$on('add-cart-event', () => {
                this.getToCart()
            });
            this.getToCart()
        }
        
    },
    watch: {
        cartItem() {
            this.totalSum();
        },
    }

}
</script>

<style scoped>
.secondary-header .basket-btn {
    display: inline-block;
}
.btn {
    min-width: initial;
}
.secondary-header .basket-wrap {
    display: flex !important;
}
</style>