
class BookSlider {

	

    constructor(sliderEl) {
		const option = {
			//rows: 2,
			dots: false,
			speed: 500,
			slidesToShow: 8,
			slidesToScroll: 6,
			autoplaySpeed: 2000,
			arrows: true,
			responsive: [
				{
					breakpoint: 1920,
					settings: {
						slidesToShow: 8,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 1375,
					settings: {
						slidesToShow: 6,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 4,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 769,
					settings: {
						arrows: true,
						slidesToShow: 3,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 576,
					settings: {
						slidesToShow: 2,
						arrows: true,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 2,
						arrows: true,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 320,
					settings: {
						slidesToShow: 1,
						arrows: true,
						slidesToScroll: 1
					}
				}
			]
		}

		$(sliderEl).slick(option);
    }

}

export default BookSlider;



