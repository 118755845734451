<template>
    <div class="form-row">
        <div class="col-12 table-responsive">
            <table class="table">
                <thead>
                    <th>Front</th>
                    <!--<th>Description</th>-->
                    <th>Name</th>
                    <th>Type</th>
                    <th class="tr_with_adjust">Price</th>
                    <th>Qty</th>
                    <th class="tr_with_adjust">Total</th>
                    <th style="width: 70px">Remove</th>
                </thead>
                <tbody>
                    <tr v-for="cart in carts" :key="cart.id">
                        <td class="text-center">
                            <img
                                :src="cart.book_list.img"
                                class="imageBooksInCart"
                            />
                        </td>
                        <!--<td class="rowFittingCartBooksTable" style="">
                        <b>Name</b>&emsp;&emsp;&nbsp;&nbsp;: {{ cart.book_list.book.title.substr(0, 24) }}<br>
                        <b>Book Type</b> :  <span v-if="cart.book_list.type == 1">Hard Cover</span>
                        <span v-else-if="cart.book_list.type == 2">Paperback</span>
                        <span v-else-if="cart.book_list.type == 3">Audio Book</span>
                        <span v-else>Not Specified</span>
                        <br><b>Price</b>&emsp;&emsp;&nbsp;&nbsp;&nbsp;: Rs. {{ (cart.price * inr_price).toFixed(2) }}<br>
                        <b>Qty</b>&emsp;&emsp;&emsp;&nbsp;&nbsp;: x{{cart.qty }}
                    </td>-->
                        <td
                            :title="cart.book_list.book.title"
                            class="rowFittingCartBooksTable"
                        >
                            {{ cart.book_list.book.title.substr(0, 24) }}
                        </td>
                        <td class="rowFittingCartBooksTable">
                            <span v-if="cart.book_list.type == 1"
                                >Hard Cover</span
                            >
                            <span v-else-if="cart.book_list.type == 2"
                                >Paperback</span
                            >
                            <span v-else-if="cart.book_list.type == 3"
                                >Audio Book</span
                            >
                            <span v-else>Not Specified</span>
                        </td>
                        <td class="rowFittingCartBooksTable">
                            Rs. {{ parseFloat(cart.price).toFixed(2) }}
                        </td>
                        <!-- <td class="rowFittingCartBooksTable">Rs. {{ (cart.price * inr_price).toFixed(2) }}</td> -->
                        <td class="rowFittingCartBooksTable">
                            <strong>x{{ cart.qty }}</strong>
                        </td>
                        <td class="rowFittingCartBooksTable">
                            Rs.
                            <span class="float-right">{{
                                parseFloat(cart.price * cart.qty).toFixed(2)
                            }}</span>
                        </td>
                        <!-- <td class="rowFittingCartBooksTable">Rs. <span class="float-right">{{  ((cart.price * inr_price) * cart.qty).toFixed(2) }}</span></td> -->
                        <td class="rowFittingCartBooksTable">
                            <button
                                type="button"
                                class="btn btn-danger"
                                @click="removeToCart(cart)"
                            >
                                <i class="fa fa-times"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr class="cart-subtotal">
                        <td colspan="5">Order Subtotal</td>
                        <td>
                            Rs.
                            <span class="float-right">{{
                                subtotal.toFixed(2)
                            }}</span>
                        </td>
                        <td></td>
                    </tr>
                    <tr v-if="is_gift" class="cart-subtotal">
                        <td colspan="5">Gift Preparation Charges</td>
                        <td>
                            Rs.
                            <span class="float-right">{{
                                gift_cahrge.toFixed(2)
                            }}</span>
                        </td>
                        <td></td>
                    </tr>
                    <tr class="cart-subtotal">
                        <td colspan="5">Delivery Chareges</td>
                        <td>
                            Rs.
                            <span class="float-right">{{
                                delivery_amount.toFixed(2)
                            }}</span>
                        </td>
                        <td></td>
                    </tr>
                    <tr class="order-total">
                        <td colspan="5"><strong>Order Total</strong></td>
                        <td>
                            <strong
                                >Rs.
                                <span class="float-right">{{
                                    (
                                        delivery_amount +
                                        subtotal +
                                        gift_cahrge
                                    ).toFixed(2)
                                }}</span></strong
                            >
                        </td>
                        <td></td>
                    </tr>
                </tfoot>
            </table>
            <input
                type="hidden"
                name="delivery_charge"
                :value="delivery_amount"
            />
            <input type="hidden" name="gift_charge" :value="gift_cahrge" />
        </div>
        <!-- <div class="form-group col-12">
        <h5><b>Payment Methods</b></h5>
        <div class="custom-radio mb-2">
            <input type="radio" name="payment_methods" id="card" @change="setDeliveryMethodCharge" v-model="payment_methods" value="card" class="radio-input">
            <label for="card" class="radio-label mb-0"> <i class="fa fa-credit-card"></i> Card Payment </label>
            <br> <span class="ml-4">Online Payment. <a href="javascript:;" @click="cardMore">Click more details</a></span> 
        </div>
        <div class="custom-radio mb-2">
            <input type="radio" name="payment_methods" id="cashdelivery" @change="setDeliveryMethodCharge" v-model="payment_methods" value="cash" class="radio-input">
            <label for="cashdelivery" class="radio-label mb-0"> <i class="fa fa-money"></i>  Cash on delivery </label>
            <br> <span class="ml-4">Pay and collect at your local post office.  <a href="javascript:;" @click="cashMore">Click more details</a></span> 
        </div>
        <div class="custom-radio">
            <input type="radio" name="payment_methods" id="bankdelivery" @change="setDeliveryMethodCharge" v-model="payment_methods" value="bank" class="radio-input">
            <label for="bankdelivery" class="radio-label mb-0"> <i class="fa fa-university"></i> Bank Deposit </label>
            <br> <span class="ml-4">Direct deposit to bank and get it delivered to home. <a href="javascript:;" @click="bankDepositMore">Click more details</a></span> 
        </div>
    </div>
    <div class="form-group col-12" v-show="payment_methods == 'bank'">
        <label>Bank Slip</label>
        <input type="file" name="slip" class="form-control file mb-1">
        <label>Bank Slip No</label>
        <input type="text" name="slip_no" class="form-control">
    </div>-->
        <br />
        <div class="form-group col-12">
            <label>
                <input
                    type="checkbox"
                    name="gift"
                    id="gift"
                    v-model="is_gift"
                />
                Send this as a gift
            </label>
            <div v-if="is_gift" class="form-group col-12">
                <!-- <label for="gift_tag">Gift Tag</label> -->
                <textarea
                    class="form-control"
                    id="gift_tag"
                    name="gift_tag"
                    placeholder="Include a gift tag (optional)"
                    v-model="gift_tag"
                ></textarea>
            </div>
        </div>
        <div class="form-group col-12" v-show="MySelectedCountry == 0">
            <br />
            <h2 style="font-weigth: 100">Delivery Methods</h2>
            <hr />
            <div class="card deliveryMethodStyles">
                <div class="form-group">
                    <table>
                        <tr>
                            <td>
                                <label class="radio-inline control-label">
                                    <input
                                        type="radio"
                                        name="delivery_methods"
                                        id="normaldelivery"
                                        @change="setDeliveryMethodCharge"
                                        v-model="delivery_methods"
                                        value="normal"
                                        class="radio-input"
                                    />
                                    <img
                                        src="frontend_assets/img/delivery/normal_delivery.png"
                                        class="deliveryMethodimages"
                                    />
                                </label>
                            </td>
                            <td>
                                <label
                                    for="normaldelivery"
                                    class="radio-label mb-0"
                                >
                                    <b>Normal delivery</b>
                                </label>
                                <br /><span class="ml-0"
                                    >Delivered to home within 2-5 days.
                                    <a href="javascript:;" @click="normalMore"
                                        >Click more details</a
                                    ></span
                                >
                            </td>
                        </tr>
                    </table>
                </div>
                <!--<table>
            <tr>
                <td>
                    <input type="radio" name="delivery_methods" id="normaldelivery" @change="setDeliveryMethodCharge" v-model="delivery_methods" value="normal" class="radio-input">
                </td>
                <td>
                    <img src='frontend_assets/img/delivery/normal_delivery.png' class="deliveryMethodimages">
                </td>
                <td>
                    <label for="normaldelivery" class="radio-label mb-0">  <b>Normal delivery</b> </label>
                    <br><span class="ml-0">Delivered to home within 2-5 days. <a href="javascript:;" @click="expressMore">Click more details</a></span>
                </td>
            </tr>
        </table> -->
            </div>
            <div class="card deliveryMethodStyles">
                <div class="form-group">
                    <table>
                        <tr>
                            <td>
                                <label class="radio-inline control-label">
                                    <input
                                        type="radio"
                                        name="delivery_methods"
                                        id="expressdelivery"
                                        @change="setDeliveryMethodCharge"
                                        v-model="delivery_methods"
                                        value="express"
                                        class="radio-input"
                                    />
                                    <img
                                        src="frontend_assets/img/delivery/express_checkout.png"
                                        class="deliveryMethodimages"
                                    />
                                </label>
                            </td>
                            <td>
                                <label
                                    for="expressdelivery"
                                    class="radio-label mb-0"
                                    ><b>Express delivery</b>
                                </label>
                                <br /><span class="ml-0"
                                    >Delivered to home within 2 days.
                                    <a href="javascript:;" @click="expressMore"
                                        >Click more details</a
                                    ></span
                                >
                            </td>
                        </tr>
                    </table>
                </div>
                <!--<table>
             <tr>
                 <td>
                     <input type="radio" name="delivery_methods" id="expressdelivery" @change="setDeliveryMethodCharge" v-model="delivery_methods" value="express" class="radio-input">
                 </td>
                 <td>
                     <img src='frontend_assets/img/delivery/express_checkout.png' class="deliveryMethodimages">
                 </td>
                 <td>
                     <label for="expressdelivery" class="radio-label mb-0"><b>Express delivery</b> </label>
                     <br><span class="ml-0">Delivered to home within 2 days. <a href="javascript:;" @click="expressMore">Click more details</a></span> 
                 </td>
             </tr>
         </table>-->
            </div>

            <div class="card deliveryMethodStyles">
                <div class="form-group">
                    <table>
                        <tr>
                            <td>
                                <label class="radio-inline control-label">
                                    <input
                                        type="radio"
                                        name="delivery_methods"
                                        id="cashdeliverypost"
                                        @change="setDeliveryMethodCharge"
                                        v-model="delivery_methods"
                                        value="cash_post"
                                        class="radio-input"
                                    />
                                    <img
                                        src="frontend_assets/img/delivery/cash_on_delivery.png"
                                        class="deliveryMethodimages"
                                    />
                                </label>
                            </td>
                            <td>
                                <label
                                    for="cashdeliverypost"
                                    class="radio-label mb-0"
                                >
                                    <b>Cash on delivery - Post</b></label
                                >
                                <br /><span class="ml-0"
                                    >Pay and collect at your local post office.
                                    <a href="javascript:;" @click="cashMorePost"
                                        >Click more details</a
                                    ></span
                                >
                            </td>
                        </tr>
                    </table>
                </div>
            </div>

            <div class="card deliveryMethodStyles">
                <div class="form-group">
                    <table>
                        <tr>
                            <td>
                                <label class="radio-inline control-label">
                                    <input
                                        type="radio"
                                        name="delivery_methods"
                                        id="cashdeliveryhome"
                                        @change="setDeliveryMethodCharge"
                                        v-model="delivery_methods"
                                        value="cash_home"
                                        class="radio-input"
                                    />
                                    <img
                                        src="frontend_assets/img/delivery/home_delivery.png"
                                        class="deliveryMethodimages"
                                    />
                                </label>
                            </td>
                            <td>
                                <label
                                    for="cashdeliveryhome"
                                    class="radio-label mb-0"
                                >
                                    <b>Cash on delivery - Home</b></label
                                >
                                <br /><span class="ml-0"
                                    >Pay and collect at your house.
                                    <a href="javascript:;" @click="cashMoreHome"
                                        >Click more details</a
                                    ></span
                                >
                            </td>
                        </tr>
                    </table>
                </div>
            </div>

            <div class="card deliveryMethodStyles">
                <div class="form-group">
                    <table>
                        <tr>
                            <td>
                                <label class="radio-inline control-label">
                                    <input
                                        type="radio"
                                        name="delivery_methods"
                                        id="pickupstore"
                                        @change="setDeliveryMethodCharge"
                                        v-model="delivery_methods"
                                        value="pickup"
                                        class="radio-input"
                                    />
                                    <img
                                        src="frontend_assets/img/delivery/pick_up_store.png"
                                        class="deliveryMethodimages"
                                    />
                                </label>
                            </td>
                            <td>
                                <label
                                    for="pickupstore"
                                    class="radio-label mb-0"
                                    ><b>Pickup in store</b>
                                </label>
                                <br /><span class="ml-0"
                                    >Pay in advance and collect it in store.
                                    <a href="javascript:;" @click="pickupMore"
                                        >Click more details</a
                                    ></span
                                >
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="custom-radio" v-show="1 == 2">
                <input
                    type="radio"
                    name="delivery_methods"
                    @change="setDeliveryMethodCharge"
                    id="overseas"
                    v-model="delivery_methods"
                    value="overseas"
                    class="radio-input"
                />
                <label for="overseas" class="radio-label mb-0">
                    <i class="fa fa-suitcase"></i> Overseas Delivery
                </label>
            </div>
        </div>
    </div>
</template>

<script>
import EventBus from "./../../event-bus";
export default {
    props: ["carts", "delivery_charges", "mycountry"],
    name: "CheckoutOrder",
    data() {
        return {
            subtotal: 0,
            weightTotal: 0,
            is_gift: false,
            gift_tag: "",
            gift_cahrge: 0,
            delivery: {
                lo: 450.0,
                express: 500.0,
                lvp: 465.0,
                pronto: 500.0,
            },
            MySelectedCountry: this.mycountry == 0 ? 0 : this.mycountry,
            // inr_price:process.env.MIX_INR_PRICE,
            delivery_amount: 0,
            payment_methods: "card",
            cash_pay_only: false,
            delivery_methods: this.mycountry == 0 ? "normal" : "overseas",
        };
    },
    methods: {
        setDeliveryMethodCharge() {
            // if(this.payment_methods == 'cash' && this.delivery_methods == 'normal') { //cash
            //     this.delivery_amount = (this.subtotal < 3000 ? this.delivery.lvp : 0);
            // } else if(this.payment_methods == 'cash' && this.delivery_methods == 'express') {
            //     this.delivery_amount = this.expressDeliveyCal();//(this.subtotal < 3000 ? this.expressDeliveyCal() : 0);
            // } else if(this.payment_methods == 'cash' && this.delivery_methods == 'pickup') {
            //     this.delivery_amount = (this.subtotal < 3000 ? this.delivery.pickup : 0);
            // } else if(this.payment_methods == 'bank' && this.delivery_methods == 'normal') { //bank
            //     this.delivery_amount = (this.subtotal < 3000 ? this.delivery.lo : 0);
            // } else if(this.payment_methods == 'bank' && this.delivery_methods == 'express') {
            //     this.delivery_amount = this.expressDeliveyCal();
            // } else if(this.payment_methods == 'bank' && this.delivery_methods == 'pickup') {
            //     this.delivery_amount = (this.subtotal < 3000 ? this.delivery.pickup : 0);
            // } else if(this.payment_methods == 'card' && this.delivery_methods == 'normal') { //card
            //     this.delivery_amount = (this.subtotal < 3000 ? this.delivery.lo : 0);
            // } else if(this.payment_methods == 'card' && this.delivery_methods == 'express') {
            //     this.delivery_amount = this.expressDeliveyCal();
            // } else if(this.payment_methods == 'card' && this.delivery_methods == 'pickup') {
            //     this.delivery_amount = (this.subtotal < 3000 ? this.delivery.pickup : 0);
            // }

            if (this.delivery_methods == "cash_post") {
                this.payment_methods = "cash";
                this.cash_pay_only = true;
            }

            if (this.delivery_methods == "cash_home") {
                this.payment_methods = "cash";
                this.cash_pay_only = true;
            }

            if (this.delivery_methods == "normal") {
                this.payment_methods = "card";
                this.cash_pay_only = false;
            }

            if (this.delivery_methods == "express") {
                this.payment_methods = "card";
                this.cash_pay_only = false;
            }

            if (this.delivery_methods == "pickup") {
                this.payment_methods = "card";
                this.cash_pay_only = false;
            }

            if (
                this.payment_methods == "cash" &&
                this.delivery_methods == "cash_post"
            ) {
                //cash
                this.delivery_amount =
                    this.subtotal < 3000 ? this.delivery.lvp : 0;
            } else if (
                this.payment_methods == "cash" &&
                this.delivery_methods == "cash_home"
            ) {
                this.delivery_amount =
                    this.subtotal < 3000 ? this.delivery.pronto : 0;
            } else if (
                this.payment_methods == "bank" &&
                this.delivery_methods == "normal"
            ) {
                //bank
                this.delivery_amount =
                    this.subtotal < 3000 ? this.delivery.lo : 0;
            } else if (
                this.payment_methods == "bank" &&
                this.delivery_methods == "express"
            ) {
                this.delivery_amount =
                    this.subtotal < 3000 ? this.delivery.express : 0;
            } else if (
                this.payment_methods == "bank" &&
                this.delivery_methods == "pickup"
            ) {
                this.delivery_amount =
                    this.subtotal < 3000 ? this.delivery.pickup : 0;
            } else if (
                this.payment_methods == "card" &&
                this.delivery_methods == "normal"
            ) {
                //card
                this.delivery_amount =
                    this.subtotal < 3000 ? this.delivery.lo : 0;
            } else if (
                this.payment_methods == "card" &&
                this.delivery_methods == "express"
            ) {
                this.delivery_amount =
                    this.subtotal < 3000 ? this.delivery.express : 0;
            } else if (
                this.payment_methods == "card" &&
                this.delivery_methods == "pickup"
            ) {
                this.delivery_amount =
                    this.subtotal < 3000 ? this.delivery.pickup : 0;
            }

            EventBus.$emit("pay-delivery-methods", {
                pay: this.payment_methods,
                delivery: this.delivery_methods,
                cash_pay: this.cash_pay_only,
            });
            // EventBus.$emit('pay-method', this.payment_methods);

            /* switch (this.payment_methods) {
                case 'bank':
                    this.delivery_amount = (this.subtotal < 3000 ? this.delivery.lo : 0);
                    break;
                case 'express':
                    this.delivery_amount = this.expressDeliveyCal();//(this.subtotal < 3000 ? this.expressDeliveyCal() : 0);
                    break;
                case 'cash':
                    this.delivery_amount = (this.subtotal < 3000 ? this.delivery.lvp : 0);
                    break;
                case 'pickup':
                    this.delivery_amount = (this.subtotal < 3000 ? this.delivery.pickup : 0);
                    break;
            } */
        },
        normalMore() {
            this.$swal({
                title: "<h1>Normal delivery</h1>",
                html: `<p>The books will be delivered directly to your home within 3 - 5 working days. Pay with cash or card.</p>`,
            });
        },
        cashMorePost() {
            this.$swal({
                title: "<h1>Cash on delivery</h1>",
                html: `<p>You will receive a letter from your local post office for collection. Pay and collect it from them within 5 working days.</p>`,
            });
        },
        cashMoreHome() {
            this.$swal({
                title: "<h1>Cash on delivery</h1>",
                html: `<p>You will receive the items to your home within 5 working days. Pay and collect from the courier</p>`,
            });
        },
        bankDepositMore() {
            this.$swal({
                title: "<h1>Bank Account Details</h1>",
                html: `<p class="text-left ml-5">Account: <strong>Venpaa Pvt Ltd</strong></p>
                <p class="text-left ml-5">Bank: <strong>Sampath Bank</strong></p>
                <p class="text-left ml-5">Branch: <strong>Kotahena</strong></p>
                <p class="text-left ml-5">Account Type: <strong>Savings Account</strong></p>
                <p class="text-left ml-5">Account No: <strong>103714011266</strong></p>
                <p>Direct deposit the amount to the below savings account and the books will be delivered directly to your home within 3-5 working days.<br> <a href="/help?a=bank-deposit">More details here</a></p>`,
            });
        },
        cardMore() {
            this.$swal({
                title: "<h1>Online Payment Details</h1>",
                html: `<p>We accepts all major credit cards, mobile wallets and internet banking payment option systems</p>`,
            });
        },
        expressMore() {
            this.$swal({
                title: "<h1>Express delivery</h1>",
                html: `<p>The books will be delivered directly to your home within 2 working days. Pay with cash or card on delivery.</p>`,
            });
        },
        pickupMore() {
            this.$swal({
                title: "<h1>Pickup in store</h1>",
                html: `<p>Pay online and collect the book at our below physical store at your convenience.</p>
                        <address>20A, Hindu College Road, 40000,<br>
                        Jaffna Town,<br>
                        Sri Lanka<br>
                        (Opposite to Jaffna Hindu College Playground’s entrance)
                        </address`,
            });
        },
        removeToCart(item) {
            axios
                .delete(`/cart/delete/${item.id}`, { _method: "delete" })
                .then(({ data }) => {
                    this.$swal({
                        toast: true,
                        position: "top-end",
                        showConfirmButton: false,
                        timer: 3000,
                        type: data.status,
                        title: data.message,
                        onClose: () => {
                            location.reload();
                        },
                    });
                })
                .catch((error) => {
                    this.$swal({
                        toast: true,
                        position: "top-end",
                        showConfirmButton: false,
                        timer: 3000,
                        type: "error",
                        title: `${error.response.data.message}`,
                    });
                });
        },
        expressDeliveyCal() {
            //1kg should be a Rs.350 upto per 1kg appending Rs.100 | weightTotal out gram
            let expressDelivey = 350;

            let TotalKg = this.weightTotal / 1000;
            let loopTotalKg = Math.floor(TotalKg);
            for (let index = 0; index < loopTotalKg; index++) {
                expressDelivey += 100 * 1;
            }
            return expressDelivey; //assign delivery_amount
        },
    },
    watch: {
        is_gift() {
            this.gift_cahrge = this.is_gift ? 50 : 0;
        },
        subtotal() {
            this.$root.data.orderSubTotal = this.subtotal;
        },
    },
    mounted() {
        EventBus.$on("clicked-event", (courierCharge) => {
            let price = courierCharge.selectedServiceValue;
            this.delivery_amount = parseFloat(price);
            if (courierCharge.selectedServiceValue == 0) {
                this.setDeliveryMethodCharge();
            }
        });
        EventBus.$on("clicked-event-get-delivery-country", (courierCountry) => {
            if (courierCountry == 0) {
                this.MySelectedCountry = 0;
                this.delivery_methods = "normal";
                this.setDeliveryMethodCharge();
            } else {
                this.mycountry = courierCountry;
                this.MySelectedCountry = 1;
                this.delivery_methods = "overseas";
            }
            this.setDeliveryMethodCharge();
        });

        // this.inr_price = (typeof process.env.MIX_INR_PRICE !== 'undefined') ?  process.env.MIX_INR_PRICE : 1.0;

        let subtotal = _.sumBy(this.carts, (item) =>
            parseFloat(item.price * item.qty)
        );

        // let subtotal = _.sumBy(this.carts, (item) => parseFloat((item.price * this.inr_price) * item.qty) );
        this.subtotal = subtotal;

        let weightTotal = _.sumBy(
            this.carts,
            (item) => parseInt(item.book_list.weight) * item.qty
        );
        this.weightTotal = weightTotal;

        let delivery = _.filter(
            this.delivery_charges,
            (o) =>
                o.weight_from <= this.weightTotal &&
                o.weight_to >= this.weightTotal
        );

        if (delivery.length > 0) {
            this.delivery = delivery[0];
        }
        this.setDeliveryMethodCharge();
    },
};
</script>

<style scoped>
.cart-subtotal {
    background-color: #fff0e0;
}
.order-total {
    background-color: #f79939;
}

.form-control.file {
    height: calc(1.5em + 0.75rem + 9px);
}
.imageBooksInCart {
    width: 100px;
}
.rowFittingCartBooksTable {
    vertical-align: middle;
}
.rowFittingCartBooksTable tr > td:last-of-type {
    text-align: center;
}
thead {
    text-align: center;
}
.tr_with_adjust {
    width: 115px;
}
.deliveryMethodStyles > .form-group {
    margin: 5px 0px;
    padding: 1%;
    background-color: #dedede36;
    border: 1px solid #ffffff;
}
.deliveryMethodStyles > .form-group > table {
    width: 100%;
}
.deliveryMethodStyles > .form-group > table > tr > td:last-of-type {
    text-align: left;
}
.deliveryMethodStyles > .form-group > table > tr > td:nth-child(1) {
    width: 120px;
}
.deliveryMethodimages {
    width: 60px;
}
</style>
