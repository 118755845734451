<template>
    <div class="container">
    <form id="mc-embedded-subscribe-form" @submit.prevent="post" class="newsletter-form popup-subscribe-form" novalidate="">
        <div class="row no-gutters justify-content-center">
            <div class="form-group col-md-8 col-lg-5">
                <input type="email" class="form-control" v-model="formData.email" placeholder="Email address"
                    aria-label="Email address for newsletter subscription" name="newsletter">
            </div>
            <div class="form-group col-md-3 col-lg-2">
                <button type="submit" class="btn btn-primary">Sign up now</button>
            </div>
        </div>
    </form>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                formData: {
                    email: ''
                }
            }
        },
        methods :{
            post() {
                axios.post(`/newsletter/subscribe`, this.formData)
                .then(({data}) => {

                    this.$swal({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        type: 'success',
                        title: `${data.message}`
                    });

                    this.formData.email = '';

                })
                .catch((error) => {
                    this.$swal({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        type: 'error',
                        title: `${error.response.data.errors.email[0]}`
                    });

                })
            }
        }
    }
</script>
