
class MainSlider {

    constructor(slider) {

		const option = {
			autoplay:true,
			speed: 500,
			lazyLoad: 'ondemand'
		};

        $(slider).slick(option);
    } 

}

export default MainSlider;

