<template>
  <button rel="nofollow" class="btn add-to-cart" @click="addtoCart" id="add_to_cart_button">Add to Cart</button>
</template>

<script>
import EventBus from '../../event-bus';
import 'viewerjs/dist/viewer.css';
import Viewer from 'v-viewer';
Vue.use(Viewer);

export default {
    props: ['bookListId', 'auth'],
    methods: {
        addtoCart() {
            axios.post(`/cart/add`, {
                qty: 1,
                book_list_id: this.bookListId
            })
                .then(({data}) => {
                    this.$swal({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        type: 'success',
                        title: `${data.message}`
                    });
                    EventBus.$emit('add-cart-event')

                })
                .catch((error) => {
                    if(this.auth) {
                        if (error.response && error.response.data) {
                            if(error.response.data.errors['book_list_id']) {
                                this.$swal({
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 3000,
                                    type: 'info',
                                    title: `${error.response.data.errors['book_list_id'][0]}`
                                });
                            } else {
                                this.$swal({
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 3000,
                                    type: 'info',
                                    title: `${error.response.data.errors['qty'][0]}`
                                });
                            }

                        }
                    } else {
                        window.location.href = '/login'
                    }

                })
        }
    }
}
</script>

<style>

</style>