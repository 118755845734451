<template>
<div class="row">
<div class="col-md-12 col-lg-2 text-center text-lg-left">
        <div class="upload-img-bar">
            <label class="label" data-toggle="tooltip" title="Change your avatar">
                <img v-if="userAvatar" :src="userAvatar" class="rounded-circle" width="128">
                <img v-else :src="avatar" class="rounded-circle" width="128">
            </label>
        </div>
    </div>
    <div class="col-md-12 col-lg-10 align-self-center text-center text-lg-left">
        <div class="upload-info ml-lg-5">
            <button id="pick-avatar" class="btn btn-warning mb-2 mb-2 py-2 px-3">Browse</button><br>
            <span>Max file size is 3MB, Minimum dimension: 400x400 And Suitable files are <code>.jpg</code>  & <code>.png</code></span>
            <avatar-cropper
                trigger="#pick-avatar"
                ref="avatarParent"
                @uploaded="handleUploaded"
                :upload-form-data="{ _method: 'PUT', _token: token}"
                :upload-url="url"
                :labels="{ submit: 'Crop', cancel: 'Cancel'}" />
        </div>
    </div>
  </div>
</template>

<script>
  import AvatarCropper from "vue-avatar-cropper"

    export default {
        props: ['avatar', 'url', 'token'],
        components: { AvatarCropper },
        data() {
            return {
                userAvatar: undefined,
            }
        },
        methods: {
            handleUploaded(resp) {
                this.userAvatar = resp.relative_url;
                this.$swal({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    type: 'success',
                    title: `${resp.message}`
                });
            }
        }
    }
</script>